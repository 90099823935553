import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { isEmpty } from 'lodash';
import { Inject } from '@plugin/inversify';
import { RelatedServiceType } from '@service/related';
import { SearchRepositoryType } from '../contracts';
import { SearchLoader } from '../components/Search/SearchLoader';
import { SearchResult } from '../components/Search/SearchResult';
/**
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
let SearchView = class SearchView extends Vue {
    constructor() {
        super(...arguments);
        this.currentPage = 1;
        this.isLoading = true;
        this.totalPage = 0;
        this.searchResult = [];
        this.relatedService = null;
        this.style = {};
    }
    get canLoadMore() {
        return this.currentPage < this.totalPage && !this.isLoading;
    }
    get paramText() {
        if (!this.queryParams.q || Array.isArray(this.queryParams.q)) {
            return '';
        }
        return this.queryParams.q || '';
    }
    get queryParams() {
        return this.$route.query;
    }
    loadMore() {
        this.currentPage += 1;
        this.loadList(this.queryParams, this.currentPage);
    }
    loadList(query, page) {
        this.isLoading = true;
        if (!isEmpty(query)) {
            if (query.label === 'all') {
                delete query.label;
            }
            this.searchRepository.load({
                ...query,
                page: `${page}`
            }).then(collection => {
                this.searchResult = [...this.searchResult, ...collection];
                // FIXME - for some reason this collection doesn't have meta has only _meta
                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                // @ts-ignore
                const meta = collection._meta;
                this.composeRelated(meta.dicts);
                if (meta.total) {
                    this.totalPage = Math.ceil(meta.total / meta.per_page || 25);
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
    composeRelated(relatedData) {
        if (typeof this.$container === 'undefined') {
            throw new Error('WithLayout.composeContent(): [this.$container] is undefined!');
        }
        this.relatedService = this.$container.get(RelatedServiceType);
        this.relatedService.storeRelated({ ...relatedData });
    }
};
__decorate([
    Inject(SearchRepositoryType)
], SearchView.prototype, "searchRepository", void 0);
SearchView = __decorate([
    Component({
        name: 'SearchView',
        components: {
            SearchLoader,
            SearchResult
        },
        mounted() {
            this.loadList(this.queryParams, 1);
        }
    })
], SearchView);
export { SearchView };
export default SearchView;
